import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';



export default function (app) {

  const isAuth = (to, from, next) => {
    if (app.config.globalProperties.$store.state.token != '') {
      next();
      return
    } else {
      sessionStorage.setItem('nextUrl', window.location.pathname);
      next('/')
    }
  };

  const routes = [
    {
      path: '/_old_home11111111111',
      name: 'Home',
      //beforeEnter: isAuth,
      component: Home
    },
    {
      path: '/',
      name: 'Auth',
      component: () => import('../views/Auth.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('../views/Auth.vue')
    },
    {
      path: '/panel',
      name: 'panelIndex',
      beforeEnter: isAuth,
      component: () => import('../views/panel/index'),
      children: [
        {
          path: 'user',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/user/index')
          }
        },
        {
          path: 'user/:id/edit',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/user/edit')
          }
        },
        {
          path: 'user/:id/view',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/user/view')
          }
        },
        {
          path: 'user/create',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/user/create')
          }
        },
        {
          path: 'user/notification',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/user/notification')
          }
        },
        {
          path: 'user/profile',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/user/profile')
          }
        },
        {
          path: 'payment',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/payment/index')
          }
        },
        {
          path: 'payment2',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/payment2/index')
          }
        },
        {
          path: 'payment/:id/view',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/payment/view')
          }
        },
        {
          path: 'payment/:id/edit',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/payment/edit')
          }
        },
        {
          path: 'calendar',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/calendar/index')
          }
        },
        {
          path: 'report',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/report/index')
          }
        },
        {
          path: 'report_impl',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/report_impl/index')
          }
        },
        {
          path: 'claim-agent',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/claim-agent/index')
          }
        },
        {
          path: 'claim',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/claim/index')
          }
        },
        {
          path: 'claim/:id/view',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/claim/view')
          }
        },
        {
          path: 'claim/:id/edit',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/claim/edit')
          }
        },
        {
          path: 'claim/:id/edit_claimant',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/claim/edit_claimant')
          }
        },
        {
          path: 'claim/file_add',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/claim/file_add')
          }
        },
        {
          path: 'claim/create',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/claim/create')
          }
        },
        {
          path: 'claim/create_claimant',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/claim/create_claimant')
          }
        },
        {
          path: 'appeal',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/appeal/index')
          }
        },
        {
          path: 'appeal/create_user',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/appeal/create_user')
          }
        },
        {
          path: 'appeal/create_user/toclaim/:claim_id',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/appeal/create_user')
          }
        },
        {
          path: 'appeal/:id/edit_user',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/appeal/edit_user')
          }
        },
        {
          path: 'appeal/:id/view',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/appeal/view')
          }
        },
        {
          path: 'appeal/:id/edit',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/appeal/edit')
          }
        },
        {
          path: 'city',
          components: {
            default: () => import('../views/panel/index'),
            panelMain: () => import('../views/panel/city/index')
          }
        },
      ]
    },
  ]

  const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
  })

  return router
}
